import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './RootState';

export const offerFormSlice = createSlice({
  name: 'offerForm',
  initialState: {
    isOpen: false,
    isSend: false,
  },
  reducers: {
    toggleForm: (state) => {
      state.isOpen = !state.isOpen;
    },
    closeForm: (state) => {
        state.isOpen = false;
    },
    setIsSend: (state) => {
      state.isSend = true;
    }
  },
});

export const { toggleForm, closeForm, setIsSend } = offerFormSlice.actions;

export const selectIsOpen = (state: RootState) => state.offerForm.isOpen;
export const isSend = (state: RootState) => state.offerForm.isSend;

export default offerFormSlice.reducer;
