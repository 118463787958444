import {Routes, Route, useLocation} from "react-router-dom";
import {StoreWrapper} from "@/components/storeWrapper/StoreWrapper";
import IndexPage from "./pages/IndexPage";
import LegalInformationPage from "./pages/LegalInformationPage";
import TiresCategoryPage from "./pages/TiresCategoryPage";
import {PartnerForm} from "./components/partnerForm/PartnerForm";
import {OfferForm} from "./components/offerForm/OfferForm";
import {PullCursor} from "./components/cursor/PullCursor";
import {useEffect, useState} from "react";
import {getCitiesList, getDefaultCity} from "@/api/services/seo.service";
import {CallbackForm} from "@/components/callbackForm/CallbackForm";

export default function App() {
    const location = useLocation();
    const [currentCity, setCurrentCity] = useState<string>('')

    useEffect(() => {
        const getCities = async () => {
            const citiesListResponse = await getCitiesList();
            const pathnameArray = location.pathname.split('/');

            if (citiesListResponse.length) {
                const currentCityFromUrl = citiesListResponse?.find((city) => {
                    return pathnameArray.includes(city?.code);
                })?.code;

                if (currentCityFromUrl) {
                    setCurrentCity(currentCityFromUrl);
                } else {
                    const defaultCityResponse = await getDefaultCity();
                    if (defaultCityResponse?.code) {
                        setCurrentCity(defaultCityResponse?.code)
                    }
                }
            }
        }

        getCities().catch(e => console.log(e))
    }, [location.pathname]);

    return (
        <div className="App">
            <StoreWrapper>
                <Routes>
                    <Route path="/:city?" element={<IndexPage city={currentCity}/>}/>
                    <Route path="/:city?/tires/:url" element={<TiresCategoryPage city={currentCity}/>}/>
                    <Route path="/:city?/legalInformation" element={<LegalInformationPage/>}/>
                </Routes>
                <PartnerForm/>
                <OfferForm/>
                <CallbackForm />
                <PullCursor/>
            </StoreWrapper>
        </div>
    )
}
