import classNames from 'classnames';
import styles from '@/components/header/Header.module.scss';
import { BurgerMenu } from '../burgerMenu/BurgerMenu';
import { useCallback, useEffect, useMemo, useState} from 'react';
import { TMenu } from "@/api/models/menu.models";
import { getMenu } from "@/api/services/menu.service";
import { useDispatch, useSelector } from 'react-redux';
import { closeMenu, selectIsOpen, toggleMenu } from '@/store/slices/Menu';
import { motion } from 'framer-motion';

export const Header = (
  {
    pageTitle,
  }: {
    pageTitle?: string,
  }
) => {
  const dispatch = useDispatch();

  const [menu, setMenu] = useState<TMenu | undefined>(undefined);
  const [isLogoFolded, setIsLogoFolded] = useState(true);
  const [isLogoAnimated, setIsLogoAnimated] = useState(false);
  const [textWidth, setTextWidth] = useState(125);
  const isMenuOpen = useSelector(selectIsOpen);

  const textRef = useCallback((node: HTMLSpanElement | null) => {
    setTextWidth(node?.offsetWidth ?? 0);
  }, []);

  const variants = useMemo(() => {
    const textTransition = {
      duration: 0.5,
      ease: [0.79, 0.09, 0.21, 0.98]
    };
    return {
      text: {
        unfolded: {
          width: textWidth,
          transition: textTransition,
        },
        folded: {
          width: 0,
          transition: textTransition,
        }
      },
      title: {
        visible: {
          opacity: 1,
          x: 0,
        },
        hidden: {
          opacity: 0,
          x: '-100%',
          transition: {
            opacity: {
              duration: 0.1,
              ease: 'easeInOut',
            },
          },
        }
      }
    };
  }, [textWidth]);

  useEffect(() => {
    getMenu()
        .then((pageDataResponse: TMenu) => {
          const menuUpdated = [...pageDataResponse,
            {
              title: 'Обратная связь',
              url: 'callback-form',
              isForm: true,
            }];
          setMenu(menuUpdated);
        });

    const timer = setInterval(() => setIsLogoAnimated(true), 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const onClick = (e: any) => {
      // Unreliable implementation, because button is null when clicked on a menu item.
      // This means that there are two dispatches fired: toggle and close. But tests showed that
      // it works fine
      const button = e.target.closest('#burger-menu');
      if (!button && isMenuOpen) {
        dispatch(closeMenu());
      }
    }
    document.body.addEventListener('click', onClick, false);
    return () => document.body.removeEventListener('click', onClick, false);
  }, [dispatch, isMenuOpen]);

  function onLogoMouseEnter() {
    setIsLogoFolded(false);
  }

  function onLogoMouseLeave() {
    setIsLogoFolded(true);
  }

  return (
    <header className={styles.header}>
      <div className={styles.header__wrapper}>
        <motion.a
          href="/"
          className={classNames(styles.logoIconLink)}
          onMouseEnter={onLogoMouseEnter}
          onMouseLeave={onLogoMouseLeave}
        >
          <span className={classNames(styles.logoIconBorder, 'logoIconBorder')}>
            <svg
              className={classNames(styles.logoIcon, 'logoIcon')}
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              {/* <path d="M32 0C40.8367 0 48.8359 3.5809 54.626 9.37398C60.4161 15.1641 64 23.1663 64 32C64 40.8367 60.4191 48.8359 54.626 54.626C48.8359 60.4161 40.8337 64 32 64C23.1633 64 15.1641 60.4191 9.37398 54.626C3.5839 48.8359 0 40.8337 0 32C0 23.1633 3.5809 15.1641 9.37398 9.37398C15.1641 3.5839 23.1663 0 32 0ZM53.1642 10.8358C47.7494 5.42088 40.2664 2.0711 32 2.0711C23.7336 2.0711 16.2506 5.42088 10.8358 10.8358C5.42088 16.2506 2.0681 23.7336 2.0681 32C2.0681 40.2664 5.41788 47.7494 10.8358 53.1642C16.2506 58.5791 23.7336 61.9319 32 61.9319C40.2664 61.9319 47.7494 58.5821 53.1642 53.1642C58.5791 47.7494 61.9319 40.2664 61.9319 32C61.9319 23.7336 58.5821 16.2506 53.1642 10.8358Z" fill="currentColor" /> */}
              {/* <circle stroke="currentColor" fill="none" strokeWidth="2" r="30" cx="32" cy="32" strokeDasharray="188.49555921538757" strokeDashoffset="94.24777960769379" transform='rotate(90)' style={{ transformOrigin: '50% 50%' }} />
              <circle stroke="currentColor" fill="none" strokeWidth="2" r="30" cx="32" cy="32" strokeDasharray="188.49555921538757" strokeDashoffset="94.24777960769379" transform='rotate(-90)' style={{ transformOrigin: '50% 50%' }} /> */}
              <path fillRule="evenodd" clipRule="evenodd" d="M38.8556 48.3594C40.4314 47.8822 41.8631 46.9067 42.8477 46.1112C44.5646 44.7245 44.6396 44.6134 44.9638 41.7859C45.426 37.7458 45.0208 33.8347 43.9342 30.1668C41.8782 23.2331 37.9791 18.1484 33.7919 14.6395C32.5582 13.607 31.6937 13.1958 31.2105 13.616C30.5291 14.2073 32.4021 15.6631 32.7473 16.0233C38.4864 22.0505 41.3259 28.72 41.1638 37.7818C41.0827 42.4193 39.9872 45.1837 38.8586 48.3624L38.8556 48.3594Z" fill="currentColor" />
              <path fillRule="evenodd" clipRule="evenodd" d="M33.1049 33.7042C33.0869 43.0812 30.2744 46.488 28.5785 49.7537L33.4051 49.7807C34.4466 47.4605 35.3711 45.4224 35.9774 42.727C37.5383 35.7753 36.3106 27.9501 33.1049 21.7278C32.3185 20.2 30.1844 16.8502 28.8907 15.7366C27.4259 14.473 26.8166 15.6946 28.1433 17.2945C28.3534 17.5496 28.4885 17.8978 28.7046 18.231C30.3554 20.7703 33.1229 25.2457 33.1079 33.7012L33.1049 33.7042Z" fill="currentColor" />
              <path fillRule="evenodd" clipRule="evenodd" d="M25.8427 27.867C25.8277 36.9258 23.2103 40.6598 20.3828 45.3813C20.9141 45.9666 22.3969 47.2002 23.6756 47.6505C30.162 38.5316 28.9103 26.4862 26.8332 20.6691C26.5541 19.8857 26.1939 19.0513 25.8997 18.3459C24.9392 16.0347 23.6636 16.8841 24.0688 18.2649C24.1648 18.598 24.3059 19.0303 24.459 19.6036C24.7471 20.6961 25.1193 22.0379 25.3775 23.4066C25.6956 25.0905 25.8337 27.2036 25.8457 27.87L25.8427 27.867Z" fill="currentColor" />
            </svg>
            <motion.span
              className={classNames(styles.logoIconTextWrp)}
              initial='unfolded'
              animate={isLogoFolded && isLogoAnimated ? 'folded' : 'unfolded'}
              variants={variants.text}
            >
              <span ref={textRef} className={classNames(styles.logoIconText, 'logoIconText')}>ШИНИНВЕСТ</span>
            </motion.span>
          </span>
        </motion.a>
        <div className={styles.burgerMenuWrapper}>
          <button
            id='burger-menu'
            className={styles.burgerMenu__wrapper}
            onClick={() => dispatch(toggleMenu())}
          >
            <div className={classNames(styles.burgerMenu, {[styles['burgerMenu__open']]: isMenuOpen})}>
              <span className={classNames(styles.burgerMenu__item, 'burgerMenu__item')}></span>
              <span className={classNames(styles.burgerMenu__item, 'burgerMenu__item')}></span>
            </div>
            <span className={classNames(styles.burgerMenu__title, 'burgerMenu__title')}>
              Меню
            </span>
            <div className={styles.BurgerMenu}>
              <BurgerMenu menu={menu}/>
            </div>
          </button>

          <motion.p
              className={classNames(styles.pageTitle, 'pageTitle')}
              variants={variants.title}
              initial='visible'
              animate={isMenuOpen ? 'hidden' : 'visible'}
          >
            {pageTitle}
          </motion.p>
        </div>
      </div>
    </header>
  );
}