import classNames from 'classnames'
import styles from './PreviewSlide.module.scss'
import {useEffect, useMemo, useRef, useState} from 'react';
import {CurvedText} from '@/atoms/curvedText/CurvedText';
import {Link} from "react-router-dom";
import {useDistanceTo} from '@/hooks/useDistanceTo';
import {motion} from 'framer-motion';
import {useDispatch, useSelector} from 'react-redux';
import {toggleForm} from '@/store/slices/PartnerFormSlice';
import {toggleForm as toggleOfferForm, isSend} from '@/store/slices/OfferFormSlice';
import {toggleForm as toggleCallbackForm} from '@/store/slices/CallbackFormSlice';

export const PreviewSlide = ({
                                 quote,
                                 backgroundVideoSrc,
                                 backgroundVideoMobileSrc,
                                 backgroundImageSrc,
                                 backgroundImageMobileSrc,
                                 id,
                                 prevCategory,
                                 nextCategory,
                                 onCanPlay,
                             }: {
    quote: string,
    backgroundVideoSrc?: string | null,
    backgroundVideoMobileSrc?: string | null,
    backgroundImageSrc?: string | null
    backgroundImageMobileSrc?: string | null,
    id: string,
    prevCategory: string,
    nextCategory: string,
    onCanPlay?: (e: any) => void,
}) => {
    const dispatch = useDispatch();
    const buttonWrapper = useRef(null);
    const vectorToButton = useDistanceTo(buttonWrapper);
    const isOfferFormSend = useSelector(isSend);
    console.log(isOfferFormSend);

    const catchButtonDistance = 100;
    const [isButtonCaught, setIsButtonCaught] = useState(false);
    const [showOfferFormMessage, setShowOfferFormMessage] = useState(true);

    const videoRef = useRef<HTMLVideoElement>(null);

    const variants = useMemo(() => {
        const buttonTransition = {
            duration: 1.2,
            ease: 'circOut',
        };
        return {
            button: {
                active: {
                    x: vectorToButton.x,
                    y: vectorToButton.y,
                    transition: buttonTransition,
                },
                inactive: {
                    x: 0,
                    y: 0,
                },
            },
            cross: {
                active: {
                    x: vectorToButton.x,
                    y: vectorToButton.y,
                    transition: {...buttonTransition, duration: 0.7},
                },
                inactive: {
                    x: 0,
                    y: 0,
                },
            },
        };
    }, [vectorToButton]);

    useEffect(() => {
        if (vectorToButton.distance > catchButtonDistance && isButtonCaught) {
            setIsButtonCaught(false);
        }
    }, [vectorToButton, isButtonCaught]);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.currentTime = 1;
        }
    }, []);

    function onButtonMouseEnter() {
        setIsButtonCaught(true);
    }

    function onButtonClick() {
        dispatch(toggleForm());
        setIsButtonCaught(false);
    }

    function onOfferMessageButtonClick() {
        dispatch(toggleOfferForm());
    }

    function onCallbackMessageButtonClick() {
        dispatch(toggleCallbackForm());
    }

    return (
        <div className={styles.pageSection} id={id}>
            <div className={styles.buttonsWrapper}>
                {
                    prevCategory &&
                    <Link to={`/tires/${prevCategory}`}>
                        <button type='button' className={styles.button}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.98965 11.5L16 17.7818L14.8345 19L8.24138 12.1091C7.91954 11.7727 7.91954 11.2273 8.24138 10.8909L14.8345 4L16 5.21815L9.98965 11.5Z"
                                    fill="white"/>
                            </svg>
                        </button>
                    </Link>
                }

                {
                    nextCategory &&
                    <Link to={`/tires/${nextCategory}`}>
                        <button type='button' className={styles.button}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14.0104 11.5L8 5.21815L9.16551 4L15.7586 10.8909C16.0805 11.2273 16.0805 11.7727 15.7586 12.1091L9.16551 19L8 17.7818L14.0104 11.5Z"
                                    fill="white"/>
                            </svg>
                        </button>
                    </Link>
                }
            </div>
            <div
                className={classNames(styles.backgroundVideoWrapper)}
            >
                {backgroundVideoSrc &&
                    <video
                        className={classNames(styles.backgroundVideo)}
                        onCanPlayThrough={onCanPlay}
                        loop={true}
                        muted={true}
                        autoPlay={true}
                        playsInline
                        src={process.env.PUBLIC_URL + backgroundVideoSrc}
                    />
                }

                {
                    backgroundVideoMobileSrc &&
                    <video
                        className={classNames(styles.backgroundVideo__mobile)}
                        loop={true}
                        muted={true}
                        autoPlay={true}
                        playsInline
                        src={process.env.PUBLIC_URL + backgroundVideoMobileSrc}
                        ref={videoRef}
                    />
                }

                {backgroundImageSrc
                    ?
                    <div
                        className={classNames(styles.backgroundImage)}
                        style={{
                            backgroundImage: `url(${backgroundImageSrc})`
                        }}
                    ></div>
                    :
                    ''
                }

                {
                    backgroundImageMobileSrc
                        ?
                        <div
                            className={classNames(styles.backgroundImage__mobile)}
                            style={{
                                backgroundImage: `url(${backgroundImageMobileSrc})`
                            }}
                        ></div>
                        :
                        ''
                }
            </div>
            <div className={styles.contentBlock}>
                <div className={styles.buttonContainer}>
                    <div className={styles.circleImageContainer}>
                        <img
                            width={117}
                            height={117}
                            src={process.env.PUBLIC_URL + '/images/disks/image1.png'}
                            className={styles.buttonImage}
                            alt={''}/>

                        <div
                            className={styles.circleText}
                        >
                            <CurvedText/>
                        </div>
                    </div>

                    <button type='button'
                            className={styles.button__circle}
                            onClick={onButtonClick}
                    >
            <span
                className={classNames(styles.buttonWrapperOuter)}
                ref={buttonWrapper}
                onMouseEnter={onButtonMouseEnter}
            >
              <motion.span
                  className={classNames(styles.buttonCircle)}
                  initial='inactive'
                  animate={isButtonCaught ? 'active' : 'inactive'}
                  variants={variants.button}
              >
                <svg
                    width="72"
                    height="72"
                    viewBox="0 0 72 72"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="36" cy="36" r="35.5" stroke="#C4C4C4"/>
                </svg>
              </motion.span>
              <motion.span
                  className={classNames(styles.buttonCross)}
                  initial='inactive'
                  animate={isButtonCaught ? 'active' : 'inactive'}
                  variants={variants.cross}
              >
                <svg
                    width="72"
                    height="72"
                    viewBox="0 0 72 72"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <line x1="36.5" y1="16" x2="36.5" y2="55" stroke="#C4C4C4"/>
                  <line x1="17" y1="34.5" x2="56" y2="34.5" stroke="#C4C4C4"/>
                </svg>
              </motion.span>
            </span>
                    </button>
                </div>
                <p className={styles.quoteText}>{quote}</p>
            </div>
            {(showOfferFormMessage && !isOfferFormSend) && (
                <div className={styles.offerFormMessage}>
                    <div className={styles.offerFormMessage__close}>
                        <span>Связаться с нами</span>
                        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg"
                             onClick={() => setShowOfferFormMessage(false)}>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M9.38594 9.18333L0.639763 17.7409L0 17.1149L8.74618 8.55738L0.639911 0.625963L1.27967 0L9.38594 7.93142L17.4922 0L18.132 0.625963L10.0257 8.55738L18.7719 17.1149L18.1321 17.7409L9.38594 9.18333Z"
                                  fill="#C4C4C4"/>
                        </svg>
                    </div>
                    <div>
                        Поможем подобрать технику и выставить счет по эксклюзивным ценам!
                    </div>
                    <div className={styles.offerFormMessage__buttons}>
                        <button className={styles.offerFormMessage__button} onClick={onOfferMessageButtonClick}>
                            Запросить КП
                        </button>
                        <button className={styles.offerFormMessage__button} onClick={onCallbackMessageButtonClick}>
                            Обратная связь
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}