import { configureStore } from '@reduxjs/toolkit';
import menuSliceReducer from './slices/Menu';
import partnerFormReducer from './slices/PartnerFormSlice';
import offerFormReducer from './slices/OfferFormSlice';
import callbackFormReducer from './slices/CallbackFormSlice';
import pullCursorReducer from './slices/PullCursor';

const reducer = {
  menu: menuSliceReducer,
  partnerForm: partnerFormReducer,
  offerForm: offerFormReducer,
  callbackForm: callbackFormReducer,
  pullCursor: pullCursorReducer,
};

export const store = configureStore({
  reducer,
});
