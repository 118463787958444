import {createSlice} from '@reduxjs/toolkit';
import {RootState} from './RootState';

export const callbackFormSlice = createSlice({
    name: 'callbackForm',
    initialState: {
        isOpen: false,
    },
    reducers: {
        toggleForm: (state) => {
            state.isOpen = !state.isOpen;
        },
        closeForm: (state) => {
            state.isOpen = false;
        },
    },
});

export const {toggleForm, closeForm} = callbackFormSlice.actions;

export const selectIsOpen = (state: RootState) => state.callbackForm.isOpen;

export default callbackFormSlice.reducer;
